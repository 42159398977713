<template>
  <div>
    <b-overlay :show="loading">
      <table-local-v-1-widget ref="refTableWithdraw" title="ประวัติการถอน" :columns="columns" :items="items">
        <template #custom_status="{row}">
          <b-badge :variant="statusFormateObj[row.status_text].variant">
            {{ statusFormateObj[row.status_text].label }}
          </b-badge>
        </template>

        <template #custom_action="{row}">
          <b-button
            v-if="row.status_text === 'pending'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="'ยกเลิกถอนเงิน'"
            :disabled="!$store.getters['AuthStore/_isCanAccessSomeFeature']"
            size="sm"
            variant="danger"
            class="btn-icon"
            style="padding: 0.2rem;"
            @click="cancelWithdraw(row)"
          >
            <feather-icon icon="XIcon" />
          </b-button>

          <b-button
            v-else-if="
              row.status_text === 'approve' ||
                row.status_text === 'reject' ||
                row.status_text === 'rejected' ||
                row.status_text === 'approved'
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="'ดูข้อมูลเพิ่มเติม'"
            size="sm"
            variant="primary"
            class="btn-icon"
            style="padding: 0.2rem;"
            @click="seeMoreDetails(row)"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
          <span v-else />
        </template>
      </table-local-v-1-widget>
      <!-- <pre>{{ items }}</pre> -->
    </b-overlay>

    <b-modal ref="refModalWithdrawTransaction" title="ข้อมูลเพิ่มเติม" size="lg" hide-footer @cancel="currentData = {}">
      <div v-if="currentData">
        <dl class="row">
          <dt class="col-sm-5">
            ยอดเงินที่ถอนได้
          </dt>
          <dd class="col-sm-7">
            <span> {{ gFormatNumberToCurrency(currentData.credit_balance || 0) }} บาท </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-5">
            จำนวนเงินที่ต้องการถอน
          </dt>
          <dd class="col-sm-7 text-success">
            <span> {{ gFormatNumberToCurrency(currentData.credit_amount || 0) }} บาท </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-5">หัก ณ ที่จ่าย ({{ Math.round(Number(currentData.withholding)) }}%)</dt>
          <dd class="col-sm-7 text-danger">
            <span>
              {{ gFormatNumberToCurrency(Number(currentData.credit_amount) * (Number(currentData.withholding) / 100)) }}
              บาท
            </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-5 ">
            ค่าธรรมเนียมถอน
          </dt>
          <dd class="col-sm-7 text-danger">
            <span> {{ gFormatNumberToCurrency(currentData.withdrawal_fee || 0) }} บาท </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-5 ">
            ยอดสุทธิ
            <small class="text-muted">
              (หัก ณ ที่จ่าย + ค่าธรรมเนียมถอน)
            </small>
            <small class="text-muted d-block">*ยอดที่คุณจะได้รับเข้าบัญชี</small>
          </dt>
          <dd class="col-sm-7 text-success">
            <span> {{ gFormatNumberToCurrency(currentData.withdraw_amount || 0) }} บาท </span>
          </dd>
        </dl>
        <dl v-if="currentData.status_text === 'approve' || currentData.status_text === 'approved'" class="row">
          <dt class="col-sm-5">
            ยอดเงินคงเหลือ
          </dt>
          <dd class="col-sm-7">
            <span> {{ gFormatNumberToCurrency(currentData.credit_total || 0) }} บาท </span>
          </dd>
        </dl>
        <hr />
        <dl v-if="currentData.status_text" class="row">
          <dt class="col-sm-5">
            สถานะ
          </dt>
          <dd class="col-sm-7">
            <b-badge :variant="statusFormateObj[currentData.status_text].variant">
              {{ statusFormateObj[currentData.status_text].label }}
            </b-badge>
          </dd>
        </dl>
        <dl v-if="currentData.status_text === 'reject' || currentData.status_text === 'rejected'" class="row">
          <dt class="col-sm-5">
            หมายเหตุจากแอดมิน
          </dt>
          <dd class="col-sm-7">
            <span>{{ currentData.note }}</span>
          </dd>
        </dl>
        <div v-if="currentData.status_text === 'approve' || currentData.status_text === 'approved'" class="mt-1">
          <span class="mb-1" style="fontWeight: 500;">หลักฐานการโอน</span>
          <div v-for="(img, index) in currentData.image_datas" :key="index">
            <b-img
              :src="img.outside_url"
              alt=""
              width="100"
              class="d-block mx-auto my-1 ct-cursor-zoom"
              @click="gZoomImage"
            />
          </div>
        </div>
      </div>
      <!-- <pre>{{ currentData }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
import mediumZoom from 'medium-zoom'

export default {
  data() {
    return {
      columns: [
        {
          label: 'วันที่',
          field: 'created_at',
          formatFn: val => this.$date(val).format('MM-DD-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ยอดเงินที่ถอนได้',
          field: 'credit_balance',
          type: 'currency',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ยอดถอน',
          field: 'credit_amount',
          type: 'currency',
          tdClass: 'text-center text-success',
          thClass: 'text-center',
        },
        {
          label: 'หัก ณ ที่จ่าย',
          field: 'withholding',
          formatFn: value => {
            const n = Math.round(Number(value))
            if (n === 0) return 'ฟรี'

            return `${n}%`
          },
          tdClass: 'text-center text-danger',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'ค่าธรรมเนียมถอน',
          field: 'withdrawal_fee',
          type: 'currency',
          tdClass: 'text-center text-danger',
          thClass: 'text-center',
        },
        {
          label: 'ยอดสุทธิ',
          field: 'withdraw_amount',
          type: 'currency',
          tdClass: 'text-center text-success',
          thClass: 'text-center',
        },
        // {
        //   label: 'ยอดเงินที่ถอนได้ (ใหม่)',
        //   field: 'credit_total',
        //   type: 'currency',
        //   tdClass: 'text-center',
        //   thClass: 'text-center',
        // },
        {
          label: 'สถานะ',
          field: 'custom_status',
          tdClass: 'text-center',
          thClass: 'text-center',
          type: 'custom',
          sortable: false,
        },
        {
          label: '',
          field: 'custom_action',
          type: 'custom',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      items: [],
      loading: false,
      statusFormateObj: {
        cancel: {
          variant: '',
          label: 'ยกเลิก',
        },
        reject: {
          variant: 'danger',
          label: 'ไม่สำเร็จ',
        },
        rejected: {
          variant: 'danger',
          label: 'ไม่สำเร็จ',
        },
        approve: {
          variant: 'success',
          label: 'สำเร็จ',
        },
        approved: {
          variant: 'success',
          label: 'สำเร็จ',
        },
        pending: {
          variant: 'warning',
          label: 'รออนุมัติ',
        },
      },
      currentData: {},
    }
  },
  created() {
    this.getHistoryWithdraw()
  },
  methods: {
    async getHistoryWithdraw() {
      this.loading = true

      const resp = await this.api.get('api/partner/withdraw/history').catch(() => null)
      // console.log('getHistoryWithdraw', resp)

      if (resp) {
        this.items = [...resp]
      }
      this.loading = false
    },
    async cancelWithdraw(row) {
      const dateDiff = this.$date().diff(this.$date(row.created_at), 'day')

      if (dateDiff <= 0) {
        this.gDisplayToast(
          'ไม่สามารถยกเลิกได้',
          'เนื่องจากรายการขอถอนนี้กำลังตรวจสอบอยู่ โปรดรอ 24 ชั่วโมง ถึงจะสามารถยกเลิกได้',
          'danger',
        )
        return
      }

      const isConfirm = await this.gCheckConfirmV1('ยืนยันยกเลิกการถอน')

      if (!isConfirm) return

      this.loading = true
      const resp = await this.api.post('api/partner/withdraw/cancel', {}).catch(() => null)
      // console.log('cancelWithdraw', resp)
      if (resp) {
        if (resp.message) {
          this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
        } else {
          this.gDisplayToast('ดำเนินการสำเร็จ')
          this.items = [...resp.history]
        }
      }
      this.loading = false
    },
    seeMoreDetails(data) {
      // console.log('seeMoreSuccessDetails', data)
      this.currentData = { ...data }
      this.$refs.refModalWithdrawTransaction.show()
    },
    zoomImage(ev) {
      const zoom = mediumZoom({ background: '#191f32', margin: 48 })
      zoom.attach(ev.target)
      zoom.on('closed', () => zoom.detach(), { once: true })
    },
  },
}
</script>

<style lang="scss" scoped></style>
