import { render, staticRenderFns } from "./PartnerWithdrawTransactionsScreen.vue?vue&type=template&id=7f57ba90&scoped=true&"
import script from "./PartnerWithdrawTransactionsScreen.vue?vue&type=script&lang=js&"
export * from "./PartnerWithdrawTransactionsScreen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f57ba90",
  null
  
)

export default component.exports